import React from "react";
import logo from "../logo-initial.webp"

const Logo = () => {
    return (

    <div>
      <img src={logo} alt="Logo" className="" />
    </div>

    )
}

export default Logo;