import './App.css';
import ConstructionPage from './pages/ConstructionPage';

/* Testing connexion front 2 back */

function App() {
  return (
    <div>
      <ConstructionPage />
    </div>
  )
}

export default App;
